@import "styles/core.scss";
.listItemContent {
  &:not(.noDescription) {
    align-items: flex-start;
  }
}

.label {
  @include lineClamp(2);
  white-space: normal;
}

.description {
  @include flex-row;
  align-items: center;
  gap: var(space-50);

  span {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-md);
  height: 48px;
  width: 48px;
  background-color: var(--color-background-secondary);
  overflow: hidden;

  img {
    height: inherit;
    width: inherit;
  }
}

// Overrides the styles of CustomSearchBox's ResultItem and prevents the styles from being overridden on hover and such
.guidesContainer.guidesContainer {
  padding: var(--space-200) 0 var(--space-300) 0;
  background-color: var(--color-neutral-50);
  overflow: hidden;

  @include hover {
    background-color: var(--color-neutral-50);
    cursor: auto;
  }
}

.guidesResultContent {
  @include flex-column;
  gap: var(--space-200);
}

.guidesHeader {
  padding: 0 var(--space-300);
  @include flex-row;
  align-items: center;
  gap: var(--space-100);

  span {
    @include heading-200;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
  }
}

.carousel {
  padding: 0 var(--space-300);
  box-sizing: border-box;
}
